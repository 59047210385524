import React from 'react';
import Layout from '../../components/layout';
import Questions from '../../components/Questions';

const RadiofrequenzNeedling = ({ data, location, ...props }) => {
	return (
		<Layout
			location={location}
			title="Radiofrequenz-Needling"
			noSlider
			{...props}
		>
			<p>
				Bei dieser Technik werden ebenfalls feine Nadeln 1-2 mm in die
				Haut geschoben und anschließend wird über dies feinen Nadeln ein
				4,0 Mh Radiofrequenz Impuls unter die Haut geleitet. Ähnlich wie
				beim normalen Microneddling, jedoch verstärkt durch die
				Radiofrequenzimpulse, wird die Neubildung körpereigenen
				Kollagengewebes und die Bildung von Wachstumsfaktoren angeregt.
			</p>
			<p>
				Ergebnis ist eine intensivere Straffung der Haut sowie Glättung
				von Fältchen.
			</p>
			<p>Narben und größere Poren werden deutlich feiner.</p>
			<p>
				Wie beim Micro-Needling können über die kleinsten Kanäle
				zusätzlich hochwirksame Anti-Aging Substanzen wie PRP in die
				Haut eingebracht werden.
			</p>
			<p>
				Empfohlen werden 2-3 Behandlungen im Abstand von ca. 4 Wochen.
				Verbesserungen treten ab der 8. Woche zu Tage.
			</p>

			<Questions title="Radiofrequenz-Needling" prefix="um" />
		</Layout>
	);
};

export default RadiofrequenzNeedling;
